<template>
	<div class="upload-image-main">
		<div class="before" v-show="!imgSrc" @click.stop>
			<input type="file" ref="Upload" @change="addImg" accept="image/*" class="upload-input">
			<img src="@/assets/img/upload-bg.svg" class="upload-icon" />
			<div class="upload-title">{{title}}</div>
      <div class="overlay" v-if="examplesImgName" @click.stop>
        <el-image :src="imgSlSrc" :preview-src-list="[imgSlSrc]" ref="elImageSl" v-show="isShow"></el-image>
				<div class="btn" @click.stop="dispatchUpload()">上传照片</div>
				<div class="btn" @click.stop="previewSlImg()">查看示例图片</div>
			</div>
		</div>
		<div class="after" v-show="imgSrc">
			<el-image :src="imgSrc" :preview-src-list="[imgSrc]" ref="elImage" v-if="imgSrc"></el-image>
			<div class="overlay">
				<div class="btn" @click="previewImg">预览 “{{ title }}”</div>
				<div class="btn" @click="delImg" v-if="isDelete">删除</div>
			</div>
		</div>
	</div>
</template>
<script>
const fileUploadURL = process.env.VUE_APP_FILE_URL + '/downloadFile/'
export default {
    data() {
        return {
            imgFile: '',
            isShow: false
        }
    },
    props: {
        name: String,
        title: String,
        isDelete: {
            type: Boolean,
            default: true
        },
        url: {
            default: ''
        },
        examplesImgName: {
            type: String,
            default: ''
        }
    },
    computed: {
        imgSlSrc() {
            const imgName = this.examplesImgName
            const imgSrc = require(`@/assets/img/${imgName}.jpg`)
            return imgSrc
        },
        imgSrc() {
            if (!this.imgFile) {
                return this.imgFile
            }
            if (typeof this.imgFile !== 'string') {
                return URL.createObjectURL(this.imgFile)
            }
            return fileUploadURL + this.imgFile
        }
    },
    watch: {
        imgFile(newVal) {
            this.$emit('uploadImage', {
                name: this.name,
                imgFile: newVal
            })
        },
        url: {
            handler(newVal, oldVal) {
                this.imgFile = newVal
            },
            immediate: true
        }
    },
    methods: {
        addImg(e) {
            const target = e.target
            const file = target.files[0]
            const fileType = file.type

            if (!fileType.match(/(jpg|jpeg|png)$/)) {
                this.$message.error('上传头像图片图片格式必须为jpg | png')
                target.value = ''
                return false
            }

            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!')
                target.value = ''
                return false
            }

            this.imgFile = file
            target.value = ''
        },
        delImg() {
            this.imgFile = ''
        },
        previewImg() {
            this.$refs.elImage.clickHandler()
        },
        previewSlImg() {
            this.$refs.elImageSl.clickHandler()
        },
        dispatchUpload() {
            this.$refs['Upload'].click()
        }
    }
}
</script>

<style lang="scss">
    .upload-image-main {
        width: 220px;
        height: 160px;
        border: 1px dashed #a8b6c8;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 20px;
        margin-bottom: 20px;
        flex-shrink: 0;
        flex-wrap: wrap;
        &:hover {
          border: 1px dashed #4395FF;
        }
        .before {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            .upload-input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
              z-index: 1;
            }
            .upload-icon {
                width: 45px;
            }
            .upload-title {
              font-size: 16px;
              color: #9badbd;
              opacity: 0.4;
            }
        }
        .after {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover .overlay {
            display: flex;
        }
        .overlay {
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left:0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: 0.7);
            z-index: 2;
            .btn {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 60px;
                border: 1px solid #fff;
                font-size: 12px;
                line-height: 2;
                color: #fff;
                margin: 6px 0;
                padding: 0 6px;
                cursor: pointer;
                &:hover {
                    background-color: rgba($color: #ffffff, $alpha: 0.3);
                }
            }
        }
	}
</style>
