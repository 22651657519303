
export default {
    data() {
        return {
            isLoading: false,
            btnNextText: '下一步'
        }
    },
    props: {
        active: Number
    },
    methods: {
        initNextBtn(flag) {
            if (flag === true) {
                this.isLoading = true
                this.btnNextText = '正在保存...'
            } else {
                this.isLoading = false
                this.btnNextText = '下一步'
            }
        },
        goPrev() {
            const active = this.active
            const newActive = active - 1
            this.$emit('update:active', newActive)
        },
        goNext() {
            const active = this.active
            const newActive = active + 1
            this.$emit('update:active', newActive)
        }
    }
}
