<template>
	<div class="company-container">
		<el-form ref="companyform" :model="companyform" :rules="companyRules" label-width="140px">
			<div class="banner-title">
				<div class="bar"></div>
				<div class="text">基本信息</div>
			</div>
			<el-form-item label="请选择区域" prop="areaCode">
				<el-select v-model="companyform.areaCode" placeholder="选择区域" :disabled="isFiveStep">
					<el-option
						v-for="item in areaList"
						:key="item.code"
						:label="item.name"
						:value="item.code">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="选择申办类型" prop="type">
				<el-select v-model="companyform.type" placeholder="选择申办类型" :disabled="isFiveStep">
					<el-option
						v-for="item in petType"
						:key="item.code"
						:label="item.label"
						:value="item.code">
					</el-option>
				</el-select>
			</el-form-item>
			<div class="banner-title">
				<div class="bar"></div>
				<div class="text">企业信息</div>
			</div>
			<el-form-item label="公司名称：" prop="name">
				<el-input v-model="companyform.name" placeholder="请输入公司名称" :disabled="isFiveStep"></el-input>
			</el-form-item>
			<el-form-item label="公司地址：" prop="address">
				<el-input v-model="companyform.address" placeholder="请输入公司地址" :disabled="isFiveStep"></el-input>
			</el-form-item>
			<el-form-item label="营业执照编码：" prop="licenseNo">
				<el-input v-model="companyform.licenseNo" maxlength="18" placeholder="请输入营业执照编码" :disabled="isFiveStep"></el-input>
			</el-form-item>
			<el-form-item label="营业执照有效期：" prop="licenseNoValidityTime">
				<el-date-picker
					v-model="companyform.licenseNoValidityTime"
					type="date"
					placeholder="请选择有效日期"
					value-format="yyyyMMdd"
					format="yyyy-MM-dd"
					:editable="false"
					:disabled="isFiveStep">
				</el-date-picker>
			</el-form-item>
			<el-form-item>
				<div slot="label">
					<i class="asterisk">*</i>
					<span>营业执照照片：</span>
				</div>
				<upload-image
					name="licenseNoImg"
					title="营业执照"
					:url="companyform.licenseNoImg"
					:isDelete="!isFiveStep"
					@uploadImage="uploadImage">
				</upload-image>
			</el-form-item>
			<div class="banner-title">
				<div class="bar"></div>
				<div class="text">法人信息</div>
			</div>
			<el-form-item label="法人姓名：" prop="legalPersonName">
				<el-input
				v-model="companyform.legalPersonName"
				placeholder="请输入法人姓名"
				maxlength="20"
				:disabled="isFiveStep"></el-input>
			</el-form-item>
			<el-form-item label="联系方式：" prop="legalPersonPhone">
				<el-input
				v-model="companyform.legalPersonPhone"
				placeholder="请输入联系方式"
				maxlength="11"
				:disabled="isFiveStep"></el-input>
			</el-form-item>
			<el-form-item label="身份证号码：" prop="legalPersonIdnumber">
				<el-input
				v-model="companyform.legalPersonIdnumber"
				placeholder="请输入身份证号码"
				maxlength="18"
				:disabled="isFiveStep"></el-input>
			</el-form-item>
			<el-form-item class="upload-container">
				<div slot="label">
					<i class="asterisk">*</i>
					<span>身份证照片：</span>
				</div>
				<upload-image
					name="legalPersonIdnumberImg1"
					title="身份证正面"
					:url="companyform.legalPersonIdnumberImg1"
					:isDelete="!isFiveStep"
					@uploadImage="uploadImage">
				</upload-image>
				<upload-image
					name="legalPersonIdnumberImg2"
					title="身份证反面"
					:url="companyform.legalPersonIdnumberImg2"
					:isDelete="!isFiveStep"
					@uploadImage="uploadImage">
				</upload-image>
			</el-form-item>
			<el-form-item class="handle-btn" v-if="!isFiveStep">
				<el-button type="primary" @click="onSave()" :disabled="isLoading">{{btnNextText}}</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
import UploadImage from '../Upload.vue'
import mixin from './mixin.js'
import { uploadImageFile } from './apply.js'
import { checkPhone, checkIdCard, checkChineseName } from '@/util/tool.js'
export default {
    components: {
        UploadImage
    },
    mixins: [mixin],
    data() {
        return {
            companyform: {
                name: '',
                address: '',
                licenseNo: '',
                licenseNoValidityTime: '',
                licenseNoImg: '',
                legalPersonName: '',
                legalPersonPhone: '',
                legalPersonIdnumber: '',
                legalPersonIdnumberImg1: '',
                legalPersonIdnumberImg2: '',
                type: '',
                areaCode: '',
                branchCode: ''
            },
            companyRules: {
                name: { required: true, message: '请输入公司名称', trigger: 'blur' },
                address: { required: true, message: '请输入公司地址', trigger: 'blur' },
                licenseNo: { required: true, message: '请输入营业执照编号', trigger: 'blur' },
                licenseNoValidityTime: { required: true, message: '请选择营业执照有效期', trigger: 'blur' },
                legalPersonName: { required: true, validator: checkChineseName, trigger: 'blur' },
                legalPersonPhone: { required: true, validator: checkPhone, trigger: 'blur' },
                legalPersonIdnumber: { required: true, validator: checkIdCard, message: '请输入身份证号码', trigger: 'blur' },
                type: { required: true, message: '选择区域', trigger: 'change' },
                areaCode: { required: true, message: '选择单位', trigger: 'change' },
                branchCode: { required: true, message: '选择申办类型', trigger: 'change' }
            }
        }
    },
    created() {
        this.$store.dispatch('findAllArea')
    },
    computed: {
        isFiveStep() {
            return this.active === 5
        },
        applyData() {
            return this.$store.getters['getApplyData']
        },
        declareId() {
            return this.$store.getters['getDeclareId']
        },
        petType() {
            return this.$store.getters['getPetType']
        },
        areaList() {
            return this.$store.getters['getAreaList']
        }
    },
    watch: {
        applyData: {
            handler(newVal, oldVal) {
                for (const key in this.companyform) {
                    this.companyform[key] = this.applyData[key]
                }
            },
            immediate: true
        }
    },
    methods: {
        uploadImage(data) {
            this.companyform[data.name] = data.imgFile
        },
        checkImage() {
            const {
                licenseNoImg,
                legalPersonIdnumberImg1,
                legalPersonIdnumberImg2
            } = this.companyform
            if (!licenseNoImg) {
                this.$message.error('请上传公司营业执照照片！')
                return false
            }

            if (!legalPersonIdnumberImg1) {
                this.$message.error('请上传身份证正面！')
                return false
            }
            if (!legalPersonIdnumberImg2) {
                this.$message.error('请上传身份证反面！')
                return false
            }
            return true
        },
        async onSave() {
            try {
                let flag = false
                this.$refs['companyform'].validate(valid => (flag = valid))
                if (!flag) return
                if (!this.checkImage()) return
                this.initNextBtn(true)
                // 上传图片
                const respData = await uploadImageFile(this.companyform)
                Object.assign(this.companyform, respData)
                // 保存数据
                const { areaCode } = this.companyform
                const params = { ...this.companyform, branchCode: areaCode }
                this.declareId && (params.id = this.declareId)
                await this.$store.dispatch('editUnitDeclareInfo', params)
                this.initNextBtn(false)
                this.goNext()
            } catch (error) {
                console.log(error)
                this.initNextBtn(false)
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            }
        }
    }
}
</script>
