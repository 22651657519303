<template>
	<div class="apply-index">
		<el-steps :active="active" align-center>
			<el-step title="单位信息"></el-step>
			<el-step title="看护人信息"></el-step>
			<el-step title="犬只信息"></el-step>
			<el-step title="其它信息"></el-step>
			<el-step title="核对信息并提交"></el-step>
		</el-steps>
		<!-- 公司信息 -->
		<Company v-if="active === 1 || active === 5" :active.sync="active"></Company>
		<!-- 看护人信息 -->
		<Minder v-if="active === 2 || active === 5" :active.sync="active"></Minder>
		<!-- 犬只信息 -->
		<PetInfo v-if="active === 3 || active === 5" :active.sync="active"></PetInfo>
		<!-- 其他信息 -->
		<Other v-if="active === 4 || active === 5" :active.sync="active"></Other>
		<!-- 提交信息 -->
		<div class="handle-btn submit" v-if="active === 5">
			<el-button type="info" plain @click="goPrev()">上一步</el-button>
			<el-button type="primary" @click="onSubmit()" :disabled="isLoading">{{btnNextText}}</el-button>
		</div>
	</div>
</template>
<script>
import Company from './apply/Company.vue'
import Other from './apply/Other.vue'
import PetInfo from './apply/PetInfo.vue'
import Minder from './apply/Minder.vue'
export default {
    components: {
        Company,
        Other,
        Minder,
        PetInfo
    },
    data() {
        return {
            active: 1,
            isLoading: false,
            btnNextText: '确认并提交'
        }
    },
    computed: {
        declareId() {
            return this.$store.getters['getDeclareId']
        }
    },
    watch: {
        $route: {
            handler(to, from) {
                const params = this.$route.params
                if (params.id) {
                    this.$store.dispatch('queryUnitDeclareDetails', {
                        id: Number(params.id)
                    }).catch(error => {
                        if (error.getType() === 'WORK_ERROR') {
                            this.$message.error(error.message)
                        }
                    })
                }
            },
            immediate: true
        }
    },
    methods: {
        async onSubmit() {
            try {
                this.initNextBtn(true)
                await this.$store.dispatch('submitUnitDeclare', {
                    id: this.declareId
                })
                this.$message.success('申办养犬信息提交成功！')
                setTimeout(() => {
                    this.initNextBtn(false)
                    this.$router.push({
                        path: '/apply-list'
                    })
                }, 1000)
            } catch (error) {
                console.log(error)
                this.initNextBtn(false)
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            }
        },
        initNextBtn(flag) {
            if (flag === true) {
                this.isLoading = true
                this.btnNextText = '正在提交...'
            } else {
                this.isLoading = false
                this.btnNextText = '确认并提交'
            }
        },
        goPrev() {
            this.active--
        }
    }
}
</script>
<style lang="scss">
	.apply-index {
		width: 1100px;
		height: 100%;
		margin: 0 auto;
		background-color: #fff;
		padding: 50px;
		.asterisk {
			color: #F56C6C;
			margin-right: 4px;
			font-style: normal;
		}
		.handle-btn {
			.el-button  {
				width: 220px;
				margin-top: 20px;
				margin-right: 10px;
				font-size: 15px;
			}
			&.submit {
				margin-left: 129px;
			}
		}
		.el-steps {
			margin: 20px auto 50px;
		}
		.el-form {
			.el-select {
				display: block;
			}
			.el-form-item {
				width: 74%;
				margin-left: auto;
				margin-right: auto;
			}
			.el-date-editor.el-input {
				width: 100%;
			}
			.upload-container {
				.el-form-item__content {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
				}
			}
		}
		.banner-title {
			height: 34px;
			width: 88%;
			background-color: #f0f0f0;
			margin: 30px auto 30px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			.bar {
				width: 6px;
				height: 100%;
				background-color: #4395FF;
				margin-right: 10px;
			}
			.text {
				font-weight: bold;
			}
		}
	}
</style>
