import * as api from '../api/index'

/** 登录接口
 * @param {*} params
 * @param {String} params.phone
 * @param {String} params.verifyCode
 * @returns
 */
export function login(params) {
    return api.POST('/declare/login', params)
}

/**
 * 短信验证码
 * @param {*} params
 * @returns
 */
export function getVerifyCode(params) {
    return api.POST('/declare/sendVerifyCode', params)
}

