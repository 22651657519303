<template>
	<div class="apply-detail-container">
		<el-alert
			:title="'审核意见：' + applyDetail.auditOpinion"
			:type="applyDetail.auditStatus === 4 ? 'success': 'error'"
			:closable="false"
			v-if="(applyDetail.auditStatus === 4 || applyDetail.auditStatus === 5) && applyDetail.auditOpinion"
		></el-alert>
		<el-form label-width="140px">
			<div class="banner-title">
				<div class="bar"></div>
				<div class="text">基本信息</div>
			</div>
			<el-form-item label="所属区域">
				<el-input :value="applyDetail.areaName"></el-input>
			</el-form-item>
			<el-form-item label="所属单位">
				<el-input :value="applyDetail.branchName"></el-input>
			</el-form-item>
			<el-form-item label="申办犬只类型">
				<el-input :value="applyDetail.type | formatType(petType)"></el-input>
			</el-form-item>
			<div class="banner-title">
				<div class="bar"></div>
				<div class="text">企业信息</div>
			</div>
			<el-form-item label="公司名称：">
				<el-input :value="applyDetail.name"></el-input>
			</el-form-item>
			<el-form-item label="公司地址：">
				<el-input :value="applyDetail.address"></el-input>
			</el-form-item>
			<el-form-item label="营业执照编码：">
				<el-input :value="applyDetail.licenseNo"></el-input>
			</el-form-item>
			<el-form-item label="营业执照有效期：">
				<el-input :value="applyDetail.licenseNoValidityTime | formatDate"></el-input>
			</el-form-item>
			<el-form-item label="营业执照照片：">
				<div class="image-container">
					<upload-image
						name="licenseNoImg"
						title="营业执照照片"
						:url="applyDetail.licenseNoImg"
						:isDelete="false">
					</upload-image>
				</div>
			</el-form-item>
			<!-- 法人信息 -->
			<div class="banner-title">
				<div class="bar"></div>
				<div class="text">法人信息</div>
			</div>
			<el-form-item label="法人姓名：">
				<el-input :value="applyDetail.legalPersonName"></el-input>
			</el-form-item>
			<el-form-item label="联系方式：">
				<el-input :value="applyDetail.legalPersonPhone"></el-input>
			</el-form-item>
			<el-form-item label="身份证号码：">
				<el-input :value="applyDetail.legalPersonIdnumber"></el-input>
			</el-form-item>
			<el-form-item label="身份证照片：">
				<div class="image-container">
					<upload-image
						name="legalPersonIdnumberImg1"
						title="身份证正面"
						:url="applyDetail.legalPersonIdnumberImg1"
						:isDelete="false">
					</upload-image>
					<upload-image
						name="legalPersonIdnumberImg2"
						title="身份证反面"
						:url="applyDetail.legalPersonIdnumberImg2"
						:isDelete="false">
					</upload-image>
				</div>
			</el-form-item>
			<!-- 看护人信息 -->
			<div class="banner-title">
				<div class="bar"></div>
				<div class="text">看护人信息</div>
			</div>
			<el-form-item label="姓名：">
				<el-input :value="applyDetail.legalPersonIdnumber"></el-input>
			</el-form-item>
			<el-form-item label="联系方式：">
				<el-input :value="applyDetail.legalPersonIdnumber"></el-input>
			</el-form-item>
			<el-form-item label="身份证号码：">
				<el-input :value="applyDetail.legalPersonIdnumber"></el-input>
			</el-form-item>
			<el-form-item label="身份证照片：">
				<div class="image-container">
					<upload-image
						name="managerIdnumberImg1"
						title="身份证正面"
						:url="applyDetail.managerIdnumberImg1"
						:isDelete="false">
					</upload-image>
					<upload-image
						name="managerIdnumberImg2"
						title="身份证反面"
						:url="applyDetail.managerIdnumberImg2"
						:isDelete="false">
					</upload-image>
				</div>
			</el-form-item>
			<!-- 犬只信息 -->
			<div class="banner-title">
				<div class="bar"></div>
				<div class="text">犬只信息</div>
			</div>
			<el-table :data="applyDetail.dogInfos" style="width: 100%" empty-text="还没犬只信息，赶快去添加吧！" border>
				<el-table-column prop="name" label="犬名" align="center"></el-table-column>
				<el-table-column prop="gender" label="性别" align="center" width="100">
					<template slot-scope="{row}">
						<template v-for="item in petGender">
							<span :key="item.code" v-if="item.code === row.gender">
								{{item.label}}
							</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column prop="breed" label="品种" align="center"></el-table-column>
				<el-table-column prop="coatColor" label="毛色" align="center"></el-table-column>
				<el-table-column label="出生年月" align="center">
					<template slot-scope="{row}">
						{{row.birthday | formatDate}}
					</template>
				</el-table-column>
				<el-table-column label="犬只照片" align="center">
					<template slot-scope="{row}">
						<el-image :src="row.petPhotoUrl | formatImage" fit="cover"></el-image>
					</template>
				</el-table-column>
				<el-table-column label="授权码" align="center" width="150">
					<template slot-scope="{row}">
						<vue-qr :text="row.authCode" :size="100" />
					</template>
				</el-table-column>
			</el-table>
			<!-- 其它信息 -->
			<div class="banner-title">
				<div class="bar"></div>
				<div class="text">其它信息</div>
			</div>
			<el-form-item label-width="50px">
				<div class="image-container">
					<upload-image
						name="purposeImg"
						title="养犬用途说明"
						:url="applyDetail.purposeImg"
						:isDelete="false">
					</upload-image>
					<upload-image
						name="manageRulesImg"
						title="养犬单位管理制度"
						:url="applyDetail.manageRulesImg"
						:isDelete="false">
					</upload-image>
					<upload-image
						name="feedingPlaceImg"
						title="饲养场所照片"
						:url="applyDetail.feedingPlaceImg"
						:isDelete="false">
					</upload-image>
					<upload-image
						name="kennelImg"
						title="犬舍照片"
						:url="applyDetail.kennelImg"
						:isDelete="false">
					</upload-image>
					<upload-image
						name="manageRulesImg2"
						title="单位上墙制度照片"
						:url="applyDetail.manageRulesImg2"
						:isDelete="false">
					</upload-image>
				</div>
			</el-form-item>
			<el-form-item label-width="50px">
				<div class="handle-btn">
					<el-button type="success" plain @click="goBack()">返回列表</el-button>
					<el-button type="danger" @click="revokeOrder()" :disabled="applyDetail.auditStatus > 1">撤 销</el-button>
				</div>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
const fileUploadURL = process.env.VUE_APP_FILE_URL + '/downloadFile/'
import moment from 'moment'
import * as $http from '@/api/apply.js'
import WorkError from '../util/WorkError.js'
import UploadImage from './Upload.vue'
import VueQr from 'vue-qr'
export default {
    data() {
        return {
            petGender: [
                { label: '雄', code: 1 },
                { label: '雌', code: 2 }
            ],
            petType: [
                { label: '工作犬', code: 1 },
                { label: '护卫犬', code: 2 },
                { label: '商品犬', code: 4 }
            ],
            applyDetail: {}
        }
    },
    components: {
        UploadImage,
        VueQr
    },
    created() {
        this.queryDetail()
    },
    filters: {
        formatImage(val) {
            return fileUploadURL + val
        },
        formatType(val, arr) {
            console.log()
            if (val) {
                const result = arr.filter(item => item.code === val)[0]
                return result.label
            }
        },
        formatDate(val) {
            return moment(val).format('YYYY-MM-DD')
        }
    },
    methods: {
        goBack() {
            this.$router.push({
                path: '/apply-list'
            })
        },
        async queryDetail() {
            try {
                const params = this.$route.params
                const res = await $http.queryUnitDeclareDetails({ id: Number(params.id) })
                if (res.respCode !== '0000') {
                    throw new WorkError({
                        workCode: res.respCode,
                        message: res.respMsg
                    })
                } else {
                    this.applyDetail = res.respData
                }
            } catch (error) {
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            }
        },
        async revokeOrder() {
            try {
                const params = this.$route.params
                const res = await $http.withdrawUnitDeclare({
                    id: params.id
                })
                if (res.respCode !== '0000') {
                    throw new WorkError({
                        workCode: res.respCode,
                        message: res.respMsg
                    })
                } else {
                    this.$message.success('撤销成功')
                    this.$router.go(0)
                }
            } catch (error) {
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
	.apply-detail-container {
		background-color: #fff;
		width: 1100px;
		height: 100%;
		margin: 0 auto;
		padding: 50px;
		.el-image {
			width: 100px;
		}
		.banner-title {
			height: 34px;
			background-color: #f0f0f0;
			margin: 30px auto 30px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			.bar {
				width: 6px;
				height: 100%;
				background-color: #4395FF;
				margin-right: 10px;
			}
			.text {
				font-weight: bold;
			}
		}
		.image-container {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		.handle-btn {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-start;
			.el-button {
				width: 220px;
				margin-top: 50px;
				margin-right: 15px;
			}
		}
	}
</style>
