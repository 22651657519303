<template>
	<div class="login-container">
		<div class="login-body" ref="loginBody">
			<div class="login-wrap">
				<el-form :model="loginForm" :rules="loginRules" ref="loginForm" class="login-form">
					<el-form-item prop="phone">
						<el-input v-model="loginForm.phone"  placeholder="输入手机号码" maxlength="11"></el-input>
					</el-form-item>
					<el-form-item prop="verifyCode">
						<el-input placeholder="输入6位短信验证码" v-model="loginForm.verifyCode" maxlength="6">
							<template slot="append">
								<el-button type="text" @click="getVerifyCode()" :disabled="btnState">{{btnText}}</el-button>
							</template>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" class="login-btn" @click="submitLogin()">注册/登录</el-button>
					</el-form-item>
				</el-form>
				<p class="login-tips">Tips : 请使用Chrome浏览器进行访问</p>
			</div>
		</div>
		<div class="footer">
            <div class="text-content">
                <img src="../assets/img/jh.png" alt="">
                <span>冀公网安备 13040302001308号</span>
            </div>
			<div class="text-content beian">
				<a href="http://beian.miit.gov.cn" target="_blank">冀ICP备2021020264号-1</a>
			</div>
        </div>
	</div>
</template>
<script>
import * as $http from '../api/login.js'
import db from '../util/localStorage.js'
import WorkError from '../util/WorkError.js'
import { checkPhone } from '../util/tool.js'
export default {
    name: "Login",
    data() {
        return {
            btnState: false,
            btnText: '获取短信验证码',
            loginForm: {
                phone: '',
                verifyCode: ''
            },
            loginRules: {
                phone: [
                    { required: true, validator: checkPhone, trigger: 'submit' },
                    { min: 11, max: 11, validator: checkPhone, trigger: 'submit' }
                ],
                verifyCode: [
                    { required: true, message: '请输入验证码', trigger: 'submit' },
                    { min: 6, max: 6, message: '请输入6位手机验证码', trigger: 'submit' }
                ]
            }
        }
    },
    methods: {
        countDown(count) {
            let timer = null
            if (count === 0) {
                this.btnState = false
                this.btnText = '获取短信验证码'
                clearTimeout(timer)
            } else {
                this.btnState = true
                this.btnText = `${count}后重新发送`
                count--
                timer = setTimeout(() => {
                    this.countDown(count)
                }, 1000)
            }
        },
        async getVerifyCode() {
            try {
                const regPhone = /^1[3-9]\d{9}$/
                const { phone } = this.loginForm
                if (!regPhone.test(phone)) {
                    this.$message.error('请输入正确的手机号验证码')
                    return
                }
                const res = await $http.getVerifyCode({
                    type: 'unitRegLogin',
                    phone: phone
                })
                if (res.respCode === '0000') {
                    this.$message.success('短信验证码已发送，请查收')
                    // 验证码倒计时
                    this.countDown(60)
                } else {
                    throw new WorkError({
                        workCode: res.respCode,
                        message: res.respMsg
                    })
                }
            } catch (error) {
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            }
        },
        async submitLogin() {
            try {
                let flag = false
                this.$refs['loginForm'].validate((valid) => (flag = valid))
                if (!flag) return
                const res = await $http.login(this.loginForm)
                if (res.respCode === '0000') {
                    // 存储数据toekn
                    db.save('__apply_authorization__', res.respData.token)
                    db.save('__apply_phone__', res.respData.phone)
                    this.goIndex()
                } else {
                    throw new WorkError({
                        workCode: res.respCode,
                        message: res.respMsg
                    })
                }
            } catch (error) {
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            }
        },
        goIndex() {
            this.$router.push({
                path: '/index',
                replace: true
            })
        }
    }
}
</script>

<style lang="scss">
.login-container {
	width: 100%;
	height: 70vh;
	background: url("../assets/img/login.jpg");
	background-position: center bottom;
	background-size: cover;
	background-repeat: no-repeat;
	margin-top: -30px;
	.footer {
		width: 100%;
		text-align: center;
		padding-top: 30px;
		padding-bottom: 20px;
		color: #383d46;
	}

	.footer .text-content {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		margin-bottom: 2px;

	}

	.footer .text-content img {
		margin-right: 5px;
	}
	.footer .beian {
		cursor: pointer;
	}

	.footer .beian a{
		color: #383d46;
	}
	.footer .beian a:hover{
		color: blue;
	}
	.login-body{
		width: 1100px;
		height: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: flex-end;
		padding: 50px 0 0 0;
		.login-wrap {
			width: 440px;
			height: 460px;
			background-color: #fff;
			padding: 120px 50px 30px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			.login-form {
				width: 100%;
				.el-input__inner {
					padding: 22px 0;
					text-indent: 15px;
					font-size: 16px;
				}
				[class^=el-icon-] {
					font-size: 20px;
				}
				.el-input-group__append {
					padding: 0 32px;
				}
			}
			.login-btn.el-button {
				width: 100%;
				font-size: 16px;
				margin: 0 auto;
			}
			.login-tips {
				color: #8590a6;
			}
		}
	}
}

</style>
