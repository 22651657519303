<template>
<div class="minder-container">
	<div class="banner-title">
		<div class="bar"></div>
		<div class="text">看护人信息</div>
	</div>
	<el-form ref="minderForm" :model="minderForm" :rules="minderRules" label-width="110px">
		<el-form-item label="姓名：" prop="managerName">
			<el-input
			v-model="minderForm.managerName"
			placeholder="看护人姓名"
			:disabled="isFiveStep"></el-input>
		</el-form-item>
		<el-form-item label="联系方式：" prop="managerPhone">
			<el-input
			v-model="minderForm.managerPhone"
			maxlength="11"
			placeholder="看护人联系方式"
			:disabled="isFiveStep"></el-input>
		</el-form-item>
		<el-form-item label="身份证号码：" prop="managerIdnumber">
			<el-input
			v-model="minderForm.managerIdnumber"
			maxlength="18"
			placeholder="看护人身份证号码"
			:disabled="isFiveStep"></el-input>
		</el-form-item>
		<el-form-item class="upload-container">
			<div slot="label">
				<i class="asterisk">*</i>
				<span>身份证照片：</span>
			</div>
			<upload-image
				name="managerIdnumberImg1"
				title="身份证正面"
				:url="minderForm.managerIdnumberImg1"
				:isDelete="!isFiveStep"
				@uploadImage="uploadImage">
			</upload-image>
			<upload-image
				name="managerIdnumberImg2"
				title="身份证正面"
				:url="minderForm.managerIdnumberImg2"
				:isDelete="!isFiveStep"
				@uploadImage="uploadImage">
			</upload-image>
		</el-form-item>
		<el-form-item class="handle-btn" v-if="!isFiveStep">
			<el-button type="info" plain  @click="goPrev()">上一步</el-button>
			<el-button type="primary" @click="onSave()" :disabled="isLoading">{{btnNextText}}</el-button>
		</el-form-item>
	</el-form>
</div>
</template>

<script>
import UploadImage from '../Upload.vue'
import { uploadImageFile } from './apply.js'
import mixin from './mixin.js'
import { checkPhone, checkIdCard, checkChineseName } from '@/util/tool.js'
export default {
    components: {
        UploadImage
    },
    mixins: [mixin],
    data() {
        return {
            minderForm: {
                managerName: '',
                managerPhone: '',
                managerIdnumber: '',
                managerIdnumberImg1: '',
                managerIdnumberImg2: ''
            },
            minderRules: {
                managerName: { required: true, validator: checkChineseName, trigger: 'blur' },
                managerPhone: { required: true, validator: checkPhone, trigger: 'blur' },
                managerIdnumber: { required: true, validator: checkIdCard, trigger: 'blur' }
            }
        }
    },
    computed: {
        isFiveStep() {
            return this.active === 5
        },
        applyData() {
            return this.$store.getters['getApplyData']
        },
        declareId() {
            return this.$store.getters['getDeclareId']
        }
    },
    watch: {
        applyData: {
            handler(newVal, oldVal) {
                for (const key in this.minderForm) {
                    this.minderForm[key] = this.applyData[key]
                }
            },
            immediate: true
        }
    },
    methods: {
        uploadImage(data) {
            this.minderForm[data.name] = data.imgFile
        },
        checkImage() {
            const { managerIdnumberImg1, managerIdnumberImg2 } = this.minderForm
            if (!managerIdnumberImg1) {
                this.$message.error('请上传身份证正面！')
                return false
            }
            if (!managerIdnumberImg2) {
                this.$message.error('请上传身份证反面！')
                return false
            }
            return true
        },
        async onSave() {
            try {
                let flag = false
                this.$refs['minderForm'].validate(valid => (flag = valid))
                if (!flag) return
                if (!this.checkImage()) return
                this.initNextBtn(true)
                // 上传图片
                const respData = await uploadImageFile(this.minderForm)
                Object.assign(this.minderForm, respData)
                // 保存信息
                await this.$store.dispatch('editUnitDeclareInfo', {
                    ...this.minderForm,
                    id: this.declareId
                })
                this.initNextBtn(false)
                this.goNext()
            } catch (error) {
                console.log(error)
                this.initNextBtn(false)
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            }
        }
    }
}
</script>
