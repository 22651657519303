<template>
	<div class="other-container">
		<div class="banner-title">
			<div class="bar"></div>
			<div class="text">其他信息补充</div>
		</div>
		<el-form label-width="0">
			<el-form-item class="upload-container">
				<upload-image
					name="purposeImg"
					title="养犬用途说明"
					:url="otherForm.purposeImg"
					:isDelete="!isFiveStep"
          examplesImgName="ytsm"
					@uploadImage="uploadImage">
				</upload-image>
				<upload-image
					name="manageRulesImg"
					title="养犬单位管理制度"
					:url="otherForm.manageRulesImg"
					:isDelete="!isFiveStep"
          examplesImgName="glzd"
					@uploadImage="uploadImage">
				</upload-image>
				<upload-image
					name="feedingPlaceImg"
					title="饲养场所平面图"
					:url="otherForm.feedingPlaceImg"
					:isDelete="!isFiveStep"
           examplesImgName="sycs"
					@uploadImage="uploadImage">
				</upload-image>
			</el-form-item>
			<el-form-item class="upload-container">
				<upload-image
					name="kennelImg"
					title="犬舍犬笼照片"
					:url="otherForm.kennelImg"
					:isDelete="!isFiveStep"
          examplesImgName="qszp"
					@uploadImage="uploadImage">
				</upload-image>
				<upload-image
					name="manageRulesImg2"
					title="上墙制度照片"
					:url="otherForm.manageRulesImg2"
					:isDelete="!isFiveStep"
          examplesImgName="sqzd"
					@uploadImage="uploadImage">
				</upload-image>
			</el-form-item>
			<el-form-item class="handle-btn" v-if="!isFiveStep">
				<el-button type="info" plain @click="goPrev()">上一步</el-button>
				<el-button type="primary" @click="onSave()" :disabled="isLoading">{{btnNextText}}</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
import UploadImage from '../Upload.vue'
import mixin from './mixin.js'
import { uploadImageFile } from './apply.js'
export default {
    components: {
        UploadImage
    },
    mixins: [mixin],
    data() {
        return {
            otherForm: {
                purposeImg: '',
                manageRulesImg: '',
                feedingPlaceImg: '',
                kennelImg: '',
                manageRulesImg2: ''
            }
        }
    },
    computed: {
        isFiveStep() {
            return this.active === 5
        },
        applyData() {
            return this.$store.getters['getApplyData']
        },
        declareId() {
            return this.$store.getters['getDeclareId']
        }
    },
    watch: {
        applyData: {
            handler(newVal, oldVal) {
                for (const key in this.otherForm) {
                    this.otherForm[key] = this.applyData[key]
                }
            },
            immediate: true
        }
    },
    methods: {
        uploadImage(data) {
            this.otherForm[data.name] = data.imgFile
        },
        checkImage() {
            if (!this.otherForm.purposeImg) {
                this.$message.error('养犬用途说明')
                return false
            }
            if (!this.otherForm.manageRulesImg) {
                this.$message.error('养犬单位管理制度')
                return false
            }
            if (!this.otherForm.feedingPlaceImg) {
                this.$message.error('饲养场所平面图')
                return false
            }
            if (!this.otherForm.kennelImg) {
                this.$message.error('犬舍犬笼照片')
                return false
            }
            if (!this.otherForm.manageRulesImg2) {
                this.$message.error('上墙制度照片')
                return false
            }
            return true
        },
        async onSave() {
            try {
                if (!this.checkImage()) return
                this.initNextBtn(true)
                // 上传图片
                const respData = await uploadImageFile(this.otherForm)
                Object.assign(this.otherForm, respData)
                // 保存信息
                await this.$store.dispatch('editUnitDeclareInfo', {
                    ...this.otherForm,
                    id: this.declareId
                })
                this.initNextBtn(false)
                this.goNext()
            } catch (error) {
                console.log(error)
                this.initNextBtn(false)
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            }
        }
    }
}
</script>
