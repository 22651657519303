var checkCode = function(val) {
    var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
    var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
    var code = val.substring(17)
    if (p.test(val)) {
        var sum = 0
        for (var i = 0; i < 17; i++) {
            sum += val[i] * factor[i]
        }

        const r = parity[sum % 11]
        if (r.toString() === code.toUpperCase()) {
            return true
        }
    }
    return false
}
var checkDate = function(val) {
    var pattern = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/
    if (pattern.test(val)) {
        var year = val.substring(0, 4)
        var month = val.substring(4, 6)
        var date = val.substring(6, 8)
        var date2 = new Date(year + '-' + month + '-' + date)
        if (date2 && date2.getMonth() === (parseInt(month) - 1)) {
            return true
        }
    }
    return false
}
var checkProv = function(val) {
    var pattern = /^[1-9][0-9]/
    var provs = {
        11: '北京',
        12: '天津',
        13: '河北',
        14: '山西',
        15: '内蒙古',
        21: '辽宁',
        22: '吉林',
        23: '黑龙江 ',
        31: '上海',
        32: '江苏',
        33: '浙江',
        34: '安徽',
        35: '福建',
        36: '江西',
        37: '山东',
        41: '河南',
        42: '湖北 ',
        43: '湖南',
        44: '广东',
        45: '广西',
        46: '海南',
        50: '重庆',
        51: '四川',
        52: '贵州',
        53: '云南',
        54: '西藏 ',
        61: '陕西',
        62: '甘肃',
        63: '青海',
        64: '宁夏',
        65: '新疆'
        // 71: "台湾",
        // 81: "香港",
        // 82: "澳门"
    }
    if (pattern.test(val)) {
        if (provs[val]) {
            return true
        }
    }
    return false
}

/**
 * 校验身份证号（严格校验）
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
export function checkIdCard(rule, value, callback) {
    if (!value) {
        return callback(new Error('请输入身份证号'))
    } else {
        const date = value.substring(6, 14)
        const code = value.substring(0, 2)
        if (checkCode(value) && checkDate(date) && checkProv(code)) {
            callback()
        } else {
            return callback(new Error('请输入正确的身份证号'))
        }
    }
}

/**
 * 校验手机号输入规则
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
export function checkPhone(rule, value, callback) {
    if (!value) {
        return callback(new Error('请输入手机号码'))
    } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        if (reg.test(value)) {
            callback()
        } else {
            return callback(new Error('请输入正确的手机号'))
        }
    }
}

/**
 * 校验统一社会信用代码
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
export function checkLicenseNo(rule, value, callback) {
    if (!value) {
        return callback(new Error('请输入营业执照编码'))
    } else {
        const reg = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/
        if (reg.test(value)) {
            callback()
        } else {
            return callback(new Error('请输入正确营业执照编码'))
        }
    }
}

/**
 * clone Object
 */
export function deepClone(obj) {
    // 先检测是不是数组和Object
    // let isArr = Object.prototype.toString.call(obj) === '[object Array]';
    const isArr = Array.isArray(obj)
    const isJson = Object.prototype.toString.call(obj) === '[object Object]'
    if (isArr) {
        // 克隆数组
        const newObj = []
        for (let i = 0; i < obj.length; i++) {
            newObj[i] = deepClone(obj[i])
        }
        return newObj
    } else if (isJson) {
        // 克隆Object
        const newObj = {}
        for (const i in obj) {
            newObj[i] = deepClone(obj[i])
        }
        return newObj
    }
    // 不是引用类型直接返回
    return obj
}

/**
 * 校验中文姓名
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
export function checkChineseName(rule, value, callback) {
    if (!value) {
        return callback(new Error('请输入姓名'))
    } else {
        const reg = /^(?:[\u4e00-\u9fa5·]{2,16})$/
        if (reg.test(value)) {
            callback()
        } else {
            return callback(new Error('请输入正确的姓名'))
        }
    }
}

// 扁平化所属单位结构
export function generateOneLevelBranchTree(branchTree, oneLevelBranchTree = []) {
    const o = {
        parentCode: branchTree.parentCode,
        name: branchTree.name,
        code: branchTree.code,
        phone: branchTree.phone,
        areaId: branchTree.areaId,
        level: branchTree.level,
        sort: branchTree.sort,
        branchType: branchTree.branchType
    }
    oneLevelBranchTree.push(o)

    if (Array.isArray(branchTree.children) && branchTree.children.length > 0) {
        branchTree.children.forEach((branch) => {
            generateOneLevelBranchTree(branch, oneLevelBranchTree)
        })
    }
}
// 删除为空的children
export function delChildrenBranchTree(branchTree) {
    for (const item of branchTree) {
        if (!item.children) continue
        if (Array.isArray(item.children) && item.children.length === 0) {
            delete item['children']
            continue
        }
        delChildrenBranchTree(item.children)
    }
}
