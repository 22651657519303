
import Vue from 'vue'
import store from '../../store'
export async function uploadImageFile(objForm) {
    try {
        let flag = false
        const formData = new FormData()
        for (const key in objForm) {
            const value = objForm[key]
            if (value instanceof File) {
                formData.append(key, value)
                flag = true
            }
        }
        if (!flag) return
        const respData = await store.dispatch('uploadImageFile', formData)
        return respData
    } catch (error) {
        console.log(error)
        if (error.getType() === 'WORK_ERROR') {
            Vue.prototype.$message.error(error.message)
        }
    }
}
