<template>
	<div class="pet-info-container">
		<div class="banner-title">
			<div class="bar"></div>
			<div class="text">犬只信息</div>
		</div>
		<div class="pet-info-main">
			<el-table :data="petData" style="width: 100%"  empty-text="还没犬只信息，赶快去添加吧！" border>
				<el-table-column prop="name" label="犬名" align="center" width="120"></el-table-column>
				<el-table-column prop="gender" label="性别" align="center" width="80">
					<template slot-scope="{row}">
						<template v-for="item in petGender">
							<span :key="item.code" v-if="item.code === row.gender">
								{{item.label}}
							</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column prop="breed" label="品种" align="center" width="120"></el-table-column>
				<el-table-column prop="coatColor" label="毛色" align="center" width="120"></el-table-column>
				<el-table-column label="出生年月" align="center" width="120">
					<template slot-scope="{row}">
						{{row.birthday | formatDate}}
					</template>
				</el-table-column>
				<el-table-column label="犬只照片" align="center">
					<template slot-scope="{row}">
						<el-image :src="row.petPhotoUrl | formatFile" fit="cover"></el-image>
					</template>
				</el-table-column>
				<el-table-column align="center" width="120" v-if="!isFiveStep">
					<template slot="header">
						<el-button type="primary" size="mini" @click="showDialog('add')">添加</el-button>
					</template>
					<template slot-scope="{row, $index}">
						<el-button type="warning" size="mini" icon="el-icon-edit" @click="onEdit(row, $index)"></el-button>
						<el-button type="danger" size="mini" icon="el-icon-delete" @click="onRemove($index)"></el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="handle-btn" v-if="!isFiveStep">
			<el-button type="info" plain @click="goPrev()">上一步</el-button>
			<el-button type="primary" @click="onSave()" :disabled="isLoading">{{btnNextText}}</el-button>
		</div>
		<el-dialog
			width="50%"
			:title="dialogTitle"
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false">
			<el-form ref="petForm" :model="petForm" :rules="petRules" label-width="90px">
				<el-form-item label="犬名" prop="name">
					<el-input v-model="petForm.name" placeholder="犬名" maxlength="6"></el-input>
				</el-form-item>
				<el-form-item label="性别" prop="gender">
					<el-select v-model="petForm.gender" placeholder="选择性别">
						<el-option
							v-for="item in petGender"
							:key="item.code"
							:label="item.label"
							:value="item.code">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="品种" prop="breed">
					<el-input v-model="petForm.breed" placeholder="品种" maxlength="10"></el-input>
				</el-form-item>
				<el-form-item label="毛色" prop="coatColor">
					<el-input v-model="petForm.coatColor" placeholder="毛色" maxlength="6"></el-input>
				</el-form-item>
				<el-form-item label="出生" prop="birthday">
					<el-date-picker
						v-model="petForm.birthday"
						type="date"
						placeholder="选择出生年月"
						value-format="yyyyMMdd"
						format="yyyy-MM-dd"
						:editable="false">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<div slot="label">
						<i class="asterisk">*</i>
						<span>犬只照片</span>
					</div>
					<upload-image
						name="petPhotoUrl"
						title="犬只照片"
						:url="petForm.petPhotoUrl"
						@uploadImage="uploadImage">
					</upload-image>
				</el-form-item>
			</el-form>
			<span slot="footer">
				<el-button @click="hideDialog()">取 消</el-button>
				<el-button type="primary" @click="onAdd(dialogState)">{{dialogBtnText}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
const fileUploadURL = process.env.VUE_APP_FILE_URL + '/downloadFile/'
import moment from 'moment'
import { deepClone } from '@/util/tool.js'
import mixin from './mixin.js'
import UploadImage from '../Upload.vue'
export default {
    components: {
        UploadImage
    },
    mixins: [mixin],
    data() {
        return {
            petData: [],
            petForm: {
                name: '',
                breed: '',
                gender: '',
                coatColor: '',
                birthday: '',
                petPhotoUrl: ''
            },
            petRules: {
                name: [{ required: true, message: '请输入犬名', trigger: 'blur' }],
                breed: [{ required: true, message: '请输入品种', trigger: 'blur' }],
                gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
                coatColor: [{ required: true, message: '请输入毛色', trigger: 'blur' }],
                birthday: [{ required: true, message: '请选择出生年月', trigger: 'change' }]
            },
            dialogVisible: false,
            dialogBtnText: '确认添加',
            dialogTitle: '添加犬只信息',
            dialogState: 'add',
            currentEditIndex: 0
        }
    },
    filters: {
        formatFile(val) {
            if (typeof val !== 'string') {
                return URL.createObjectURL(val)
            }
            return fileUploadURL + val
        },
        formatDate(val) {
            return moment(val).format('YYYY-MM-DD')
        }
    },
    computed: {
        isFiveStep() {
            return this.active === 5
        },
        applyData() {
            return this.$store.getters['getApplyData']
        },
        declareId() {
            return this.$store.getters['getDeclareId']
        },
        petGender() {
            return this.$store.getters['getPetGender']
        }
    },
    watch: {
        applyData: {
            handler(newVal, oldVal) {
                for (const item of this.applyData.dogInfos) {
                    this.petData.push({
                        name: item.name,
                        gender: item.gender,
                        breed: item.breed,
                        coatColor: item.coatColor,
                        birthday: item.birthday,
                        petPhotoUrl: item.petPhotoUrl
                    })
                }
            },
            immediate: true
        }
    },
    methods: {
        uploadImage(data) {
            this.petForm[data.name] = data.imgFile
        },
        async onSave() {
            try {
                if (this.petData.length < 1) {
                    this.$message.error('至少添加一只犬信息！')
                    return false
                }
                this.initNextBtn(true)
                await this.uploadImageFile()
                await this.$store.dispatch('uploadUnitDeclareDogsInfo', {
                    dogInfos: this.petData,
                    id: this.declareId
                })
                this.initNextBtn(false)
                this.goNext()
            } catch (error) {
                console.log(error)
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            }
        },
        async uploadImageFile() {
            try {
                let flag = false
                const formData = new FormData()
                this.petData.forEach((item, index) => {
                    for (const key in item) {
                        const value = item[key]
                        const name = key + index
                        if (value instanceof File) {
                            formData.append(name, value)
                            flag = true
                        }
                    }
                })
                if (!flag) return
                const respData = await this.$store.dispatch('uploadImageFile', formData)
                for (const key in respData) {
                    const len = key.length
                    const index = key.substring(len - 1, len)
                    const name = key.substring(0, len - 1)
                    this.petData[index][name] = respData[key]
                }
            } catch (error) {
                this.initNextBtn(false)
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            }
        },
        onAdd(state) {
            let flag = false
            this.$refs['petForm'].validate(valid => (flag = valid))
            if (!flag) return
            if (!this.petForm.petPhotoUrl) {
                this.$message.error('请上传犬只照片！')
                return
            }
            const item = deepClone(this.petForm)
            if (state === 'add') {
                this.petData.push(item)
                this.hideDialog()
            }
            if (state === 'edit') {
                const index = this.currentEditIndex
                this.petData.splice(index, 1, item)
                this.hideDialog()
            }
        },
        onEdit(row, index) {
            const item = deepClone(row)
            this.currentEditIndex = index
            this.petForm = item
            this.showDialog('edit')
        },
        onRemove(index) {
            this.petData.splice(index, 1)
        },
        clearForm() {
            this.$refs['petForm'].resetFields()
            this.petForm['petPhotoUrl'] = ''
        },
        hideDialog() {
            this.clearForm()
            this.dialogVisible = false
        },
        showDialog(type = 'add') {
            if (type === 'edit') {
                this.dialogTitle = '编辑犬只信息'
                this.dialogBtnText = '确认编辑'
            }
            if (type === 'add') {
                this.dialogBtnText = '确认添加'
                this.dialogTitle = '添加犬只信息'
            }
            this.dialogState = type
            this.dialogVisible = true
        }
    }
}
</script>
<style lang="scss" scoped>
.pet-info-container {
	.pet-info-main {
		width: 86%;
		margin: 0 auto;
	}
	.handle-btn {
		width: 100%;
		margin: 30px auto 0;
		display: flex;
		justify-content: center;
	}
	.add-btn {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.el-form {
		width: 100%;
		.el-select {
			display: block;
		}
	}
	.el-table {
		.el-image {
			width: 80px;
		}
	}
}

</style>
