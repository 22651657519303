<template>
	<div class="apply-list-container">
		<el-table :data="applyList" empty-text="当前暂无申办记录" border v-loading="isLoading">
			<el-table-column prop="acceptanceNo" label="受理单号"></el-table-column>
			<el-table-column label="犬只类型" width="100">
				<template slot-scope="{row}">
					<template v-for="item in petType">
						<span :key="item.code" v-if="item.code === row.type">
							{{item.label}}
						</span>
					</template>
				</template>
			</el-table-column>
			<el-table-column prop="name" label="公司名称" show-overflow-tooltip></el-table-column>
			<el-table-column prop="licenseNo" label="营业执照号" show-overflow-tooltip></el-table-column>
			<el-table-column prop="branchName" label="所属单位"></el-table-column>
			<el-table-column prop="auditStatus" label="办理进度" width="110">
				<template slot-scope="{row}">
					<template v-for="item in auditStatus">
						<el-tag :key="item.code" v-if="item.code === row.auditStatus" :type="item.type">
							{{item.label}}
						</el-tag>
					</template>
				</template>
			</el-table-column>
			<el-table-column label="操作" min-width="110">
				<template slot-scope="{row}">
					<el-button type="primary" @click="queryDetail(row)" size="mini">明细</el-button>
					<el-button type="success" @click="goPrint(row)" size="mini" v-if="row.auditStatus === 4">打印</el-button>
					<el-button
						type="warning"
						size="mini"
						@click="goEditPage(row)"
						v-if="row.auditStatus === 0">编辑</el-button>
					<!-- <el-button type="danger" @click="revokeOrder(row)" size="mini" v-if="row.auditStatus <= 1">撤销</el-button> -->
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
import * as $http from '@/api/apply.js'
import WorkError from '../util/WorkError.js'
export default {
    data() {
        return {
            isLoading: false,
            applyList: [],
            petType: [
                { label: '工作犬', code: 1 },
                { label: '护卫犬', code: 2 },
                { label: '商品犬', code: 4 }
            ],
            auditStatus: [
                { label: '待提交', code: 0, type: 'info' },
                { label: '待受理', code: 1, type: 'warning' },
                { label: '待现场核验', code: 2, type: 'primary' },
                { label: '待通过', code: 3, type: 'primary' },
                { label: '通过', code: 4, type: 'success' },
                { label: '驳回', code: 5, type: 'danger' }
            ]
        }
    },
    created() {
        this.queryUnitDeclareList()
    },
    methods: {
        async queryUnitDeclareList() {
            try {
                this.isLoading = true
                const res = await $http.queryUnitDeclareList({
                    pageIndex: 1,
                    pageSize: 99
                })
                if (res.respCode !== '0000') {
                    throw new WorkError({
                        workCode: res.respCode,
                        message: res.respMsg
                    })
                } else {
                    this.applyList = res.respData.unitDeclareList
                }
            } catch (error) {
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            } finally {
                this.isLoading = false
            }
        },
        async queryDetail(row) {
            this.$router.push({
                name: 'apply-detail',
                params: {
                    id: row.id
                }
            })
        },
        async goEditPage(row) {
            this.$router.push({
                name: 'apply',
                params: {
                    id: row.id
                }
            })
        },
        async goPrint(row) {
            window.open(`./print.html?declareId=${row.id}`)
        },
        async revokeOrder(row) {
            try {
                const res = await $http.withdrawUnitDeclare({
                    id: row.id
                })
                if (res.respCode !== '0000') {
                    throw new WorkError({
                        workCode: res.respCode,
                        message: res.respMsg
                    })
                } else {
                    this.$message.success('撤销成功')
                    this.$router.go(0)
                }
            } catch (error) {
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            }
        }
    }
}
</script>
<style scoped lang="scss">
	.apply-list-container {
		width: 1100px;
		min-height: 100%;
		margin: 0 auto;
		background-color: #fff;
		padding: 50px 30px;
	}
</style>
